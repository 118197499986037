
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import { toDate } from '../utils/common'
import store from '../store'
@Options({
  components: {}
})
export default class business extends Vue {
  toDate = toDate;
  d:any = {
    originalName: [],
    partners: [],
    changeRecords: [],
    branches: [],
    employees: [],
    industry: {
      subIndustry: ''
    },
    area: {
      province: ''
    },
    contactInfo: {
      phoneNumber: '',
      webSite: [],
      email: ''
    },
    revokeInfo: {
      cancelReason: ''
    }
  }

  loading = false

  created () {
    this.loading = true
    api.getInfoVerify(store.getters['company/get']).then((data:any) => {
      this.d = data
    }).finally(() => {
      this.loading = false
    })
  }
}
